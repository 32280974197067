/* SIGNUP OVERLAY MOBILE---- */
.signup {
  padding: 1rem 4rem;
  background: #fff;

  position: relative;
  /* border: 1px solid red; */
  height: 100%;
}

h3 {
  font-family: "ProductSansBold";

  font-size: 2.3em;
  color: #000;
  text-align: center;
  text-align: left;
  letter-spacing: 0.2px;
}

.flag {
  border-radius: 0.6rem;
}

.signup p {
  font-family: "ProductSansRegular";
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.2px;
  color: #000000;
  text-align: left;
}

.signup_input_controls {
  display: flex;
}

.country_input {
  width: 100%;
  display: flex;
  /* background: yellow; */
  height: 3rem;
  position: relative;
  justify-content: flex-start;
  color: #000;
  border-bottom: 1px solid #c9c9c9;
}

.current_selected,
.option {
  display: flex;
  /* padding: 1rem 1rem; */
  justify-items: flex-start;
  align-items: center;
  padding: 1rem 1rem;
}

.current_selected div {
  padding: 0rem 0rem;
}

.country_options {
  /*  background: red;*/
  padding: 0px 5px;
  justify-items: flex-start;
  position: absolute;
  width: 100%;
  top: 3rem;
  box-shadow: 0px 0px 92px rgba(0, 0, 0, 0.04),
    0px 0px 14.95px rgba(0, 0, 0, 0.02);
  padding-bottom: 1rem;
  overflow: scroll;
  height: 10rem;
  background: #fff;
}

.country_options.hidden {
  display: none;
}

.option {
  margin-top: 1rem;
}

.country_options div {
  padding: 0 10px;
}

.current_selected .country_name {
  padding-left: 0.6rem;
  letter-spacing: 0.2px;
}

.drop_down_icon {
  height: 1rem;
  width: 1rem;
  background-image: url("../images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 1rem;
  display: none1;
  position: absolute;
  right: 1rem;
  top: 0.2rem;
}

.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0;
}

.signup_input_controls {
  flex-direction: column;
}

.signup .btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  flex-direction: column;
}

.new_btn_primary {
  padding: 1rem 3rem;
  color: #000;
  background: #9ba0fc;
  text-align: center;
  border-radius: 1.2rem;
  color: #fff;
}

.new_btn_primary_transparent {
  padding: 20px;
  background: #fff;
  color: #000;
}

.graphics {
  /*    background: red;*/
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.graphics img {
  width: 10vw;
}

/* SIGNUP OVERLAY MOBILE--END----- */

/* SIGNUP OVERLAY DESKTOP--START----- */

@media only screen and (min-width: 600px) {
  .signup_input_controls {
    flex-direction: row;
  }

  .signup .btn_container {
    flex-direction: row;
  }

  .graphics {
    position: absolute;
    top: -3vw;
    right: -1.5vw;
  }

  .graphics img {
    width: 8vw;
  }

  .signup {
    border-radius: 3rem;
  }

  .country_options {
    height: 8rem;
  }
}

/* SIGNUP OVERLAY MOBILE--END----- */
